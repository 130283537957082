.layout {
    background-color: #ECECEC;
    min-height: 100vh;
    &__element {
        width: 100%;
        min-height: 100vh
    }
    &__content {
        min-height: 100vh;
        background-color: #F8F8F8;
        padding-bottom: 20px;
        position: relative
    }
}