.normal {
    color: #333333;
    box-shadow: 0 2px 2px 0 rgb(211, 211, 211);

    &__box {

        p {
            padding-left: 80px;
        }
    }
}