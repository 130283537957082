/** @format */

.mochi-modal-wrapper {
    .modal {
        position: fixed;
        box-shadow: none;
    }

    .modal-dialog {
        width: 600px;
        margin: 0;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        box-shadow: none;
    }

    .modal-content {
        border: none;

        .modal-body {
            position: relative;
            padding: 45px 15px;
        }

        .modal-footer {
            border-top: none;
        }
    }

    .mochi-modal-image-close-outer {
        position: absolute;
        top: -25px;
        right: 15px;
        cursor: pointer;
    }

    .mochi-modal-image-close {
        width: 50px;
        height: 49px;
        object-fit: contain;
    }

    .mochi-modal-body {
        &-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0.0128571em;
            color: #000000;
            text-align: center;
        }

        &-name {
            font-size: 18px;
            margin-bottom: 0;
            line-height: 30px;
            font-weight: 700;
            text-align: center;
            letter-spacing: 0.0128571em;
            color: #000000;
            text-transform: uppercase;
        }

        &-email {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.0128571em;
            color: #000000;
            margin-bottom: 30px;
        }

        &-other-account {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.0128571em;
            text-decoration: underline;
            color: #f8ad3e;
            margin-bottom: 0;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 768px) {
    .mochi-modal-wrapper {
        .modal-dialog {
            width: calc(100% - 40px);
        }
    }
}
