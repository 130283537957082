::-webkit-input-placeholder {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
}

:-ms-input-placeholder {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
}

::placeholder {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
}

input {
    height: 60px;
    border: none !important;
    padding: 20px 20px;
    border-radius: 15px !important;
    margin-bottom: 5px;
    &:focus {
        box-shadow: inset 0 -1px 0 #ddd !important;
    }
    &:active {
        box-shadow: inset 0 -1px 0 #ddd !important;
    }
}

.input {
    &__error {
        border: 2px solid #EB5757 !important;
    }

    &__message__error {
        color: #EB5757
    }
}

.input__group {
    text-align: left;
    max-width: 80% !important;
    margin: auto
}

.show_pw {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #23AC38;
    text-decoration: underline;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}