.header {
    width: 100%;
    height: 60px;
    background: #FFCB08;
    border-radius: 0 0 15px 15px;
    text-align: center;
    position: relative;

    & img {
        width: 35px;
        height: 35px;
        cursor: pointer;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%)
    }

    & p {
        font-size: 24px;
        font-weight: bold;
        line-height: 60px
    }
}