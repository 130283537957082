.sign-up {
    position: relative;

    &__title {
        width: 40%;
        margin: 30px auto 31px;
        text-align: center;

        & p {
            font-size: 22px;
            font-weight: bold
        }
    }

    // &_image {
    //     height: 252px;
    //     width: 200px;
    // }

    .button__social {
        width: 368px;
        height: 56px;

        .facebook__box {
            img {
                width: 16px;
                height: 30px;
            }
        }

        .google__box {
            img {
                width: 33px;
                height: 20px;
            }
        }

        .button__social__box {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                position: relative;
                left: 0;
                top: 0;
                transform: translateY(0%);
            }

            p {
                padding-left: 10px;
                margin-bottom: 0;
            }
        }
    }

    &__switch {
        font-size: 18px;
        font-weight: bold;
        margin: 15px auto
    }

    .box--redirect {
        margin-top: 30px;

        span {
            font-size: 18px;
            line-height: 22px;
            font-style: normal;
            font-weight: 500;
        }

        .sign-up__span--redirect {
            color: #42A5F5;
            text-decoration: underline;
            font-weight: 700;
            margin-left: 10px;
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 1300px) {
    .sign-up {
        &__title {
            & p {
                font-size: 20px;
            }
        }

        .button__social {
            width: 328px;
            height: 46px;

            .button__social__box {
                p {
                    padding-left: 10px;
                }
            }
        }

        .box--redirect {
            span {
                font-size: 16px;
                height: 20px;
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .sign-up {
        &__title {
            width: 60%;
        }

        .button__social {
            width: 288px;

            .button__social__box {
                p {
                    padding-left: 10px;
                }
            }
        }

        .box--redirect {
            span {
                font-size: 14px;
                height: 18px;
            }
        }
    }
}

@media only screen and (max-width: 300px) {
    .sign-up {
        &__title {
            width: 100%;
        }

        &__image {
            width: 150px !important;
        }

        .button__social {
            width: 208px;

            .facebook__box {
                img {
                    width: 12px;
                    height: 26px;
                }
            }

            .google__box {
                img {
                    width: 28px;
                    height: 16px;
                }
            }

            .button__social__box {
                p {
                    padding-left: 10px;
                    font-size: 12px;
                }
            }
        }

        &__switch {
            font-size: 16px;
            margin: 6px auto
        }

        .box--redirect {
            margin-top: 15px;

            span {
                font-size: 12px;
                height: 16px;
            }
        }
    }
}