.login {
    position: relative;
    &__boxTitle {
        width: 100%; 
        margin: 30px auto 30px; 
        text-align: center;
        & p {
            font-size: 24px; 
            font-weight: bold
        }
    }
    
    &__switch {
        font-size: 20px;
        font-weight: bold;
        margin: 20px auto
    }
    
    &__boxInput {
        max-width: 450px; 
        margin: auto
    }
    
    &__action {
        width: 100%; 
        margin: 20px auto 50px auto; 
        text-align: center
    }
    
    &__title__forgotPw {
        color: #2F80ED; 
        text-decoration: underline; 
        cursor: pointer
    }
    
    &__title__register {
        color: #2F80ED;
        text-decoration: underline
    }
    &__capcha {
        display: flex;
        justify-content: center;
    }
}