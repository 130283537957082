/** @format */

.button__action {
    height: 50px;
    border-radius: 25px;
    width: 250px;
    cursor: pointer;
    position: relative;
    margin: 10px auto;
    user-select: none;

    &--active {
        background: linear-gradient(270deg, #1c8c2c 0%, #46a503 100%);
    }

    &--inactive {
        background: #e0e0e0;
    }

    &__boxActive {
        height: 50px;
        border-radius: 25px;
        min-width: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(270deg, #24ad37 0%, #57cb04 100%);
        cursor: pointer;
        position: absolute;
        left: 0;
        top: -6px;

        &:active {
            top: 0;
        }
    }

    & p {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        line-height: 50px;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 300px) {
    .button__action {
        height: 40px;
        border-radius: 20px;
        width: 150px;

        &__boxActive {
            height: 40px;
            border-radius: 20px;
            width: 150px;
        }

        & p {
            font-size: 16px;
            line-height: 40px;
        }
    }
}
