/** @format */

.container--shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.5;
    z-index: 3;
}

.pop-up {
    width: 500px;
    height: 282px;
    position: fixed;
    background-color: white;
    padding: 111px 0 15px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    border-radius: 20px;
    z-index: 4;

    .pop-up--close {
        position: absolute;
        top: -20px;
        right: 15px;
        height: auto;
        width: 40px;
        pointer-events: all;
        cursor: pointer;
        z-index: 4;
    }

    &__image {
        display: block;
        position: absolute;
        top: -111px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .pop-up__image {
            width: 230px;
            height: auto;
        }

        .pop-up__message {
            font-family: "Quicksand", sans-serif;
            font-weight: 700;
            width: 23vw;
            font-size: 18px;
            line-height: 26px !important;
            margin-bottom: 0;
            width: 100%;
            padding: 0 70px 15px;
        }

        .button-ibox {
            min-width: 185px;
            height: 44px;
            line-height: 44px;
            font-weight: 700;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(
                83.47deg,
                #58cc02 9.02%,
                #23ac38 90.81%
            );
            box-shadow: 0 0.3vw 0 #209b32;
            border: none;
            color: white;
            border-radius: 25px;
        }
    }
}

@media screen and (max-width: 768px) {
    .pop-up {
        width: calc(100%);
        top: unset;
        bottom: 0;
        right: 0;
        left: 0;
        transform: translate(0, 0);
        border-radius: 20px 20px 0 0;
    }
}
