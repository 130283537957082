.button__social {
    height: 50px;
    width: 280px;
    border-radius: 25px;
    position: relative;
    margin: 10px auto 15px;

    &__box {
        height: 100%;
        width: 100%;
        border-radius: 25px;
        top: -6px;
        left: 0;
        cursor: pointer;
        position: relative;
        text-align: left;

        & img {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            height: 25px;
        }

        p {
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            line-height: 50px;
        }

        &:active {
            top: 0;
        }
    }
}

@media only screen and (max-width: 390px) {
    .button__social {
        height: 40px;
        width: 260px;
        border-radius: 20px;
        &__box {
            border-radius: 20px;
            top: -3px;
        }

        & img {
            height: 20px;
        }

        p {
            font-size: 16px;
            line-height: 40px;
        }
    }
}